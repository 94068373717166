import { gql } from '@apollo/client'

export const salesMixInsights = gql`
  query salesMixInsights(
    $kitchenId: Int!
    $menuId: Int
    $start: DateTime!
    $period: PeriodEnum!
  ) {
    kitchenToPosProductsCount(kitchenId: $kitchenId, linked: false)
    salesMixInsights(
      kitchenId: $kitchenId
      start: $start
      period: $period
      menuId: $menuId
    ) {
      totalGp
      totalRevenue
      totalProfit
      unlinkedDishes
      salesMixData {
        dishId
        dishName
        automated {
          qtySold
          profit
          revenue
          gp
        }
        manual {
          qtySold
          profit
          revenue
          gp
        }
        isDog
        isStar
      }
    }
  }
`

export const upsertDishesSoldMutation = gql`
  mutation createOrUpdateDishesSold(
    $kitchenId: Int!
    $data: CreateOneDishesSoldObject!
  ) {
    createOrUpdateDishesSold(kitchenId: $kitchenId, data: $data) {
      id
    }
  }
`
