import { useQuery } from '@apollo/client'
import {
  Button,
  DashboardNumberCard,
  DashboardNumberCardCompact,
  DashboardSection,
  Typography,
} from '@getjelly/jelly-ui'
import { IconTrendingDown, IconTrendingUp } from '@tabler/icons-react'
import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { dashboardKitchenQuery } from './graphql'
import { KitchenNavigation } from './KitchenNavigation'

import { PeriodEnum, Query, QueryDashboardKitchenArgs } from '../../api'
import { useKitchen } from '../../app/contexts/SelectedKitchen'
import { Loader } from '../../components'
import { CalendarPeriodSelector } from '../../components/Insights/CalendarPeriodSelector'
import { NewLayout } from '../../components/newUi'
import { routes } from '../../routes/Paths'
import { percentageChange, toFixedIfNecessary } from '../../utils'

export function Kitchen() {
  const navigate = useNavigate()
  const { selectedKitchen } = useKitchen()
  const [periodEnum, setPeriodEnum] = useState<PeriodEnum>(PeriodEnum.Week)

  const { data, loading } = useQuery<
    {
      dashboardKitchen: Query['dashboardKitchen']
      kitchenToPosProductsCount: Query['kitchenToPosProductsCount']
    },
    QueryDashboardKitchenArgs
  >(dashboardKitchenQuery, {
    fetchPolicy: 'no-cache',
    skip: !selectedKitchen,
    variables: {
      kitchenId: selectedKitchen?.id ?? 0,
      period: periodEnum,
    },
  })

  const stockTakeDiff = useMemo(() => {
    if (!data) {
      return { diff: 0, text: 'text-success-400' }
    }

    if (data.dashboardKitchen.previousStockValue === 0) {
      return { diff: 0, text: 'text-success-400' }
    }

    const diff = percentageChange(
      data.dashboardKitchen.previousStockValue,
      data.dashboardKitchen.totalStockValue,
    )

    if (diff >= 0) {
      return {
        diff,
        text: 'text-error-400',
        trending: 'up',
      }
    }

    return {
      diff: Math.abs(diff),
      text: 'text-success-400',
      trending: 'down',
    }
  }, [data])

  if (!data && loading) {
    return (
      <>
        <NewLayout title="Kitchen" hideBack={true} />
        <KitchenNavigation />

        <Loader />
      </>
    )
  }

  return (
    <>
      <NewLayout title="Kitchen" hideBack={true} />
      <KitchenNavigation />

      <div className="w-full flex justify-center">
        <div className="w-full max-w-[32rem] pb-6">
          <CalendarPeriodSelector
            value={periodEnum}
            onChange={setPeriodEnum}
            className="pt-4"
          />

          <DashboardSection title="Cookbook">
            {!!data?.kitchenToPosProductsCount && (
              <DashboardNumberCard
                title="Menu items imported from your POS integration"
                data={data.kitchenToPosProductsCount.toLocaleString()}
                accent="secondary"
                bottomContent={
                  <div className="px-8 pt-4">
                    <Button
                      label="Review New Menu Items"
                      onClick={() =>
                        navigate(
                          routes.Kitchen + routes.Import + routes.Unlinked,
                        )
                      }
                      className="w-full"
                    />
                  </div>
                }
              />
            )}

            <DashboardNumberCard
              title="Updates In Cookbook"
              data={
                data?.dashboardKitchen.totalCookbookUpdates.toString() ?? '0'
              }
              accent="secondary"
              onClick={() => navigate(`${routes.Book}/${routes.List}`)}
            />

            {data?.dashboardKitchen.topMenuDiff && (
              <DashboardNumberCardCompact
                title={data.dashboardKitchen.topMenuDiff.name}
                subtitle={
                  toFixedIfNecessary(
                    data.dashboardKitchen.topMenuDiff.target,
                    2,
                  ) + '% Target'
                }
                data={
                  toFixedIfNecessary(
                    data.dashboardKitchen.topMenuDiff.diff,
                    2,
                  ) + '%'
                }
                accent={
                  data.dashboardKitchen.topMenuDiff.diff >=
                  data.dashboardKitchen.topMenuDiff.target
                    ? 'success'
                    : 'error'
                }
                onClick={() => navigate(routes.Book + routes.Menu)}
              />
            )}
          </DashboardSection>

          <DashboardSection title="Stock">
            <DashboardNumberCard
              title="Total Stock Value"
              data={(
                data?.dashboardKitchen.totalStockValue ?? 0
              ).toLocaleString(undefined, {
                currency: 'GBP',
                style: 'currency',
              })}
              dataCaption="(excl. VAT)"
              accent="secondary"
              bottomContent={
                <div className="space-x-1 w-full flex items-center justify-center">
                  {stockTakeDiff.trending === 'up' ? (
                    <IconTrendingUp size={18} className={stockTakeDiff.text} />
                  ) : (
                    <IconTrendingDown
                      size={18}
                      className={stockTakeDiff.text}
                    />
                  )}

                  <Typography style="caption" className={stockTakeDiff.text}>
                    {toFixedIfNecessary(stockTakeDiff.diff, 2)}%
                  </Typography>

                  <Typography style="caption" className="text-primary-400">
                    vs this time last period
                  </Typography>
                </div>
              }
              onClick={() => navigate(`${routes.Stock}/${routes.Take}`)}
            />

            {data?.dashboardKitchen.topStockTake && (
              <DashboardNumberCardCompact
                title={data.dashboardKitchen.topStockTake.name}
                subtitle={format(
                  data.dashboardKitchen.topStockTake.date,
                  'EEE dd MMM',
                )}
                data={data.dashboardKitchen.topStockTake.value.toLocaleString(
                  undefined,
                  {
                    currency: 'GBP',
                    style: 'currency',
                  },
                )}
                onClick={() => navigate(routes.Stock + routes.Take)}
              />
            )}
          </DashboardSection>

          <DashboardSection title="Orders">
            <DashboardNumberCard
              title="Placed This Period"
              data={data?.dashboardKitchen.totalOrdersPlaced.toString() ?? '0'}
              dataCaption="Orders"
              accent="secondary"
              onClick={() => navigate(`${routes.Order}/${routes.History}`)}
            />

            {data?.dashboardKitchen.recentOrder && (
              <DashboardNumberCardCompact
                title={data.dashboardKitchen.recentOrder.supplierName}
                subtitle={format(
                  data.dashboardKitchen.recentOrder.date,
                  'EEE dd MMM HH:mm',
                )}
                data={data.dashboardKitchen.recentOrder.creatorName}
                onClick={() => navigate(routes.Order + routes.History)}
              />
            )}
          </DashboardSection>
        </div>
      </div>
    </>
  )
}
