import { gql } from '@apollo/client'

export const getMenusQuery = gql`
  query getMenusQuery($orderBy: [MenuOrderByInput!], $where: MenuWhereInput) {
    menuList(orderBy: $orderBy, skip: 0, take: 500, where: $where) {
      nodes {
        _cursor
        id
        name
      }
    }
  }
`
