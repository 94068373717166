import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { RefObject } from 'react'
import { useNavigate } from 'react-router-dom'

import { RecipeToIngredient, RecipeToRecipe } from 'api'
import { NewButton, NewTextField } from 'components'
import { Icon } from 'components/newUi/Icon'
import { Typography } from 'components/newUi/Typography'
import { useWindowSize } from 'hooks'
import { routes } from 'routes/Paths'
import { useTheme } from 'styles/newUi'
import { capitaliseEachWord } from 'utils'
import {
  calculateRecipeIngredientPrice,
  calculateRecipeToRecipePrice,
} from 'utils/form/price'

import { useStyles } from '../styles'

export const Item = ({
  item,
  itemRef,
  handleSelectUnit,
  removeItem,
  updateItem,
  index,
  handleEditIngredient,
  render,
}: {
  index: number
  item: RecipeToRecipe | RecipeToIngredient
  handleSelectUnit: (item: RecipeToRecipe | RecipeToIngredient) => void
  handleEditIngredient: (item: RecipeToRecipe | RecipeToIngredient) => void
  removeItem: (item: RecipeToRecipe | RecipeToIngredient) => void
  updateItem: (item: RecipeToRecipe | RecipeToIngredient) => void
  active?: boolean
  render: number
  itemRef: RefObject<HTMLDivElement> | undefined
}) => {
  const classes = useStyles()
  const formik = useFormikContext()
  const { theme } = useTheme()
  const navigate = useNavigate()

  const disableUnitSelect =
    item.unit?.name === 'portion' && item.__typename === 'RecipeToRecipe'

  const { gte: isDesktop } = useWindowSize('md')

  let cost = 0

  const isRecipe = item.__typename === 'RecipeToRecipe'

  if (item.__typename === 'RecipeToRecipe') {
    cost = calculateRecipeToRecipePrice(item)
  }

  if (item.__typename === 'RecipeToIngredient') {
    cost = calculateRecipeIngredientPrice(item)
  }

  const handleEdit = () => {
    if (item.__typename === 'RecipeToIngredient') {
      handleEditIngredient(item)
    } else if (item.__typename === 'RecipeToRecipe') {
      navigate(`${routes.Create}/${item.childRecipe.id}${routes.Recipe}`)
    }

    return
  }

  let name = ''
  switch (item.__typename) {
    case 'RecipeToIngredient':
      name = item.ingredient.product.name
      break
    case 'RecipeToRecipe':
      name = item.childRecipe.name
      break
  }

  return (
    <>
      <div
        ref={itemRef}
        style={{
          borderBottomColor: theme.palette.primary[10].toHexString(),
          borderBottomStyle: 'solid',
          borderBottomWidth: 1,
          maxHeight: 96,
          paddingLeft: 12,
          paddingRight: 12,
          width: isDesktop ? '100%' : undefined,
        }}
        data-testid={`row_${name}`}
      >
        <div className={classes.itemRoot}>
          <div className={classes.detail}>
            <div
              data-testid={`row_${name}`}
              style={{
                alignItems: 'center',
                display: 'grid',
                gridTemplateColumns: '1fr auto auto',
              }}
            >
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div onClick={handleEdit} style={{ cursor: 'pointer' }}>
                <Icon
                  iconName="edit"
                  style={{
                    color: theme.palette.secondary[100].toHexString(),
                    height: 18,
                  }}
                />
              </div>
              <Typography
                variant="subtitle2"
                className={classes.productName}
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                testId="product-name"
              >
                {capitaliseEachWord(name)}
              </Typography>
            </div>
            <Typography
              variant="caption"
              style={{
                color: isRecipe
                  ? theme.palette.secondary[100].toHexString()
                  : theme.palette.primary[60].toHexString(),
                display: 'inline-block',
                overflow: 'hidden',
                paddingRight: isDesktop ? 12 : undefined,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              testId="row-source"
            >
              {isRecipe && 'recipe'}
              {!isRecipe &&
                (item as RecipeToIngredient).ingredient.product.supplier.name}
            </Typography>
          </div>
        </div>
        <div className={classes.amount}>
          <NewTextField
            className={classes.input}
            defaultValue={item.amount ?? ''}
            render={render}
            focus={
              // @ts-ignore
              formik.errors.components
                ? // @ts-ignore
                  Boolean(formik.errors.components[index]?.amount) && render > 0
                : false
            }
            error={
              // @ts-ignore
              formik.errors.components
                ? // @ts-ignore
                  formik.errors.components[index]?.amount
                : undefined
            }
            errorStyle={{
              display: 'none',
            }}
            inputClassName={classes.inputInner}
            inputProps={{
              'data-hj-allow': '',
              'data-testid': 'input-quantity',
              step: '0.01',
              style: {
                textAlign: 'center',
                width: '70px',
              },
            }}
            onChange={(value) => {
              formik.setFieldValue(`components[${index}].amount`, value)
              updateItem({ ...item, amount: value as number })
            }}
            type="number"
          />

          <NewButton
            text={
              item.__typename === 'RecipeToRecipe'
                ? item.unit?.name
                : (item as RecipeToIngredient)?.unit?.name
            }
            disabled={disableUnitSelect}
            preventSubmission={true}
            onClick={() => {
              handleSelectUnit(item)
            }}
            className={clsx(
              'ingredient-change-unit-button',
              classes.unitButton,
            )}
            data-testid="ingredient-change-unit-button"
          >
            <Icon
              iconName="swapHoriz"
              style={{
                color: theme.palette.secondary[100].toHexString(),
                paddingLeft: 4,
              }}
            />
          </NewButton>
          <div
            data-testid="row-cost"
            style={{ fontWeight: 500, textAlign: 'right' }}
          >
            £ {cost.round(2)}
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className={classes.arrow}
            onClick={(event) => {
              event.stopPropagation()
              removeItem(item)
            }}
          >
            <Icon
              iconName="clear"
              style={{
                color: theme.palette.error[100].toHexString(),
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}
