import { useMutation } from '@apollo/client'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import { Dish, Mutation, MutationUpdateOneDishArgs } from 'api'
import {
  NewButton,
  NewTextField,
  Typography,
  NewLayout,
  LeaveConfirmationModal,
} from 'components/newUi'
import { updateDishMutation } from 'screens/Create/graphql'
import { theme } from 'styles/newUi/ThemeProvider'
import { cleanErrorMessage } from 'utils'
import { calculateGrossProfit, newCalculateMenuPrice } from 'utils/form/price'

import { errorToast, successToast } from '../../../components/toasts'
import { useStyles } from '../styles'

export interface IProps {
  id?: number
  dish?: Dish
}

const validationSchema = Yup.object({
  gpTarget: Yup.number().required('Required'),
  overheads: Yup.number().required('Required'),
  price: Yup.number().required('Required'),
  vat: Yup.number()
    .required('Required')
    .lessThan(100, 'Must be smaller than 100')
    .moreThan(-1, 'Must be 0 or higher'),
})

export const Costing = observer(({ id, dish }: IProps) => {
  const classes = useStyles()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const totalCost = dish?.recipe.unitCost ?? 0
  const [override, setOverride] = useState(false)

  const [updateDish] = useMutation<
    { updateOneDish: Mutation['updateOneDish'] },
    MutationUpdateOneDishArgs
  >(updateDishMutation, {
    awaitRefetchQueries: true,
    refetchQueries: ['getRecipeById'],
  })

  const navigate = useNavigate()

  const processInput = (value: unknown) => {
    if (!value) return value as number

    return Number(parseFloat(String(value))?.toFixed(1))
  }

  const costPerPortion: number = Number(totalCost) / (dish?.portion ?? 1)

  function onKeyDown(keyEvent: React.KeyboardEvent<unknown>) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  const gpValue = calculateGrossProfit(
    Number(dish?.price ?? 0),
    dish?.overheads ?? 0, // overheads
    costPerPortion,
    dish?.vat ?? 0, // vat
  )

  return (
    <Formik
      onSubmit={async (state) => {
        setIsSubmitting(true)
        const { vat, overheads, gpTarget, price } = state
        updateDish({
          variables: {
            data: {
              gpTarget,
              id: Number(id),
              overheads,
              price: Number(price),
              unitCost: costPerPortion,
              vat,
            },
          },
        })
          .then(({ data }) => {
            setIsSubmitting(false)
            const { name } = data?.updateOneDish as Dish

            successToast(`Saved ${name}`)
            setOverride(true)
            navigate(-1)
          })
          .catch((error) => {
            setIsSubmitting(false)
            errorToast(cleanErrorMessage(error.message))
          })
        return
      }}
      validationSchema={validationSchema}
      initialValues={{
        gpTarget:
          Number(gpValue?.toFixed(1)) !== 0 ? Number(gpValue?.toFixed(1)) : 0,
        overheads: dish?.overheads && dish?.overheads > 0 ? dish?.overheads : 0,
        price:
          dish?.price && dish.price > 0 ? dish.price.toFixed(2) : undefined,
        vat: dish?.vat && dish.vat > 0 ? dish.vat : 0,
      }}
    >
      {(formik) => (
        // eslint-disable-next-line
        <form onKeyDown={onKeyDown}>
          <NewLayout
            title="Pricing"
            subtitle="Create Dish"
            bottomBackgroundColor="initial"
          />

          <LeaveConfirmationModal
            action="leave"
            title="You have unsaved changes, are you sure you want to leave the screen?"
            text=""
            onConfirm={() => {
              formik.resetForm()
              navigate(-1)
            }}
            show={formik.dirty && !formik.isSubmitting && !override}
          />
          <div className={classes.costingRow}>
            <div style={{ overflow: 'hidden' }}>
              <div style={{ textTransform: 'capitalize' }}>
                <Typography variant="subtitle1" style={{ paddingBottom: 5 }}>
                  Cost per Serving
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: theme.palette.primary[60].toHexString() }}
                >
                  Based on ingredient cost
                </Typography>
              </div>
            </div>
            <div
              style={{
                alignItems: 'flex-end',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="subtitle1"
                style={{ fontWeight: 500, marginBottom: 5 }}
                data-testid={'costPerServing'}
              >
                £{costPerPortion?.toFixed(2)}
              </Typography>
            </div>
          </div>
          <div className={classes.costingRow}>
            <div style={{ overflow: 'hidden' }}>
              <div style={{ textTransform: 'capitalize' }}>
                <Typography variant="subtitle1" style={{ paddingBottom: 5 }}>
                  GP
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: theme.palette.primary[60].toHexString() }}
                >
                  Gross Profit Percentage
                </Typography>
              </div>
            </div>
            <div
              style={{
                alignItems: 'flex-end',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <NewTextField
                hideNumberControls
                className={classes.costingField}
                inputProps={{
                  'data-hj-allow': '',
                  'data-testid': 'gpTargetInput',
                  pattern: '/d*',
                  step: '0.01',
                  style: {
                    textAlign: 'right',
                    width: 90,
                  },
                }}
                type="number"
                error={formik.errors.gpTarget}
                errorStyle={{ textAlign: 'right' }}
                endAdornment="%"
                value={formik.values.gpTarget}
                onChange={(value) => {
                  const { overheads, vat } = formik.values
                  const temp = processInput(value)

                  const menuPrice = newCalculateMenuPrice({
                    gpTarget: temp ?? 0,
                    overheads: overheads ?? 0,
                    recipeCost: costPerPortion,
                    vat: vat ?? 0,
                  })?.toFixed(2)

                  formik.setFieldValue('gpTarget', temp)
                  formik.setFieldValue(
                    'price',
                    processInput(menuPrice).toFixed(2),
                  )
                  formik.setFieldValue('gpTarget', processInput(value))
                }}
              />
            </div>
          </div>
          <div className={classes.costingRow}>
            <div style={{ overflow: 'hidden' }}>
              <div style={{ textTransform: 'capitalize' }}>
                <Typography variant="subtitle1" style={{ paddingBottom: 5 }}>
                  VAT
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: theme.palette.primary[60].toHexString() }}
                >
                  Current VAT rate
                </Typography>
              </div>
            </div>
            <div
              style={{
                alignItems: 'flex-end',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <NewTextField
                hideNumberControls
                className={classes.costingField}
                type="number"
                error={formik.errors.vat}
                inputWrapStyles={{ textAlign: 'right' }}
                errorStyle={{ textAlign: 'right' }}
                inputProps={{
                  'data-hj-allow': '',
                  'data-testid': 'vatInput',
                  pattern: '/d*',
                  step: '0.01',
                  style: {
                    textAlign: 'right',
                    width: 90,
                  },
                }}
                value={formik.values.vat}
                endAdornment="%"
                onChange={(value) => {
                  const { overheads, gpTarget } = formik.values
                  const temp = processInput(value)

                  const menuPrice = newCalculateMenuPrice({
                    gpTarget: gpTarget ?? 0,
                    overheads: overheads ?? 0,
                    recipeCost: costPerPortion,
                    vat: temp ?? 0,
                  })?.toFixed(2)

                  formik.setFieldValue(
                    'price',
                    processInput(menuPrice).toFixed(2),
                  )
                  formik.setFieldValue('vat', temp)
                }}
              />
            </div>
          </div>
          <div className={classes.costingRow}>
            <div style={{ overflow: 'hidden' }}>
              <div style={{ textTransform: 'capitalize' }}>
                <Typography variant="subtitle1" style={{ paddingBottom: 5 }}>
                  Overheads
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: theme.palette.primary[60].toHexString() }}
                >
                  For wastage, packing, delivery etc.
                </Typography>
              </div>
            </div>
            <div
              style={{
                alignItems: 'flex-end',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <NewTextField
                hideNumberControls
                className={classes.costingField}
                value={formik.values.overheads}
                inputProps={{
                  'data-hj-allow': '',
                  'data-testid': 'overheadsInput',
                  step: '0.01',
                  style: {
                    textAlign: 'right',
                    width: 90,
                  },
                }}
                errorStyle={{ textAlign: 'right' }}
                type="number"
                error={formik.errors.overheads}
                endAdornment="%"
                onChange={(value) => {
                  const { vat, gpTarget } = formik.values
                  const temp = processInput(value)

                  const menuPrice = newCalculateMenuPrice({
                    gpTarget: gpTarget ?? 0,
                    overheads: temp ?? 0,
                    recipeCost: costPerPortion,
                    vat: vat ?? 0,
                  })

                  formik.setFieldValue(
                    'price',
                    processInput(menuPrice).toFixed(2),
                  )
                  formik.setFieldValue('overheads', temp)
                }}
              />
            </div>
          </div>
          <div
            style={{
              bottom: 0,
              maxWidth: 900,
              position: 'fixed',
              width: '100%',
            }}
          >
            <div
              style={{
                alignItems: 'center',
                backgroundColor: theme.palette.secondary[50].toHexString(),
                color: theme.palette.common.light.toHexString(),
                display: 'flex',
                justifyContent: 'space-between',
                padding: 10,
                width: '100%',
              }}
            >
              <Typography variant="subtitle1">Menu Price</Typography>
              <div
                style={{
                  alignItems: 'flex-end',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <NewTextField
                  hideNumberControls
                  className={classes.costingField}
                  value={formik.values.price}
                  inputProps={{
                    'data-hj-allow': '',
                    'data-testid': 'priceInput',
                    pattern: '/d*',
                    step: '0.01',
                    style: {
                      width: '110px',
                    },
                  }}
                  error={formik.errors.price}
                  startAdornment="£"
                  type="number"
                  onChange={(value) => {
                    const newGp = calculateGrossProfit(
                      Number(value), // price
                      formik.values.overheads!, // overheads
                      costPerPortion,
                      Number(formik.values.vat), // vat
                    )

                    const gpTarget = processInput(newGp) ?? 0

                    formik.setFieldValue('gpTarget', gpTarget, true)
                    formik.setFieldValue('price', processInput(value))
                  }}
                />
              </div>
            </div>
            <div
              style={{
                backgroundColor: theme.palette.secondary[100].toHexString(),
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: 3,
                paddingTop: 3,
              }}
            >
              <NewButton
                disabled={!formik.dirty || isSubmitting}
                text={isSubmitting ? undefined : 'Save Pricing'}
                disableLoaderStyles
                loading={isSubmitting}
                className={classes.button}
                type="button"
                onClick={formik.handleSubmit}
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
})
