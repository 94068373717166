import { useNavigate } from 'react-router-dom'

import { RecipeToRecipe } from 'api'
import { Icon, Typography } from 'components/newUi'
import { buildViewRoute, routes } from 'routes/Paths'
import { RecipeOrIngredient } from 'screens/Create/Ingredients/types'
import { useTheme } from 'styles/newUi'
import { capitaliseEachWord, toFixedIfNecessary } from 'utils'

type Props = {
  item: RecipeOrIngredient
}

export function Row({ item }: Props) {
  const navigate = useNavigate()
  const { theme } = useTheme()

  return (
    // eslint-disable-next-line
    <div
      data-testid={`row_${item.ingredient?.product.name}`}
      onClick={() => {
        if (item.__typename === 'RecipeToIngredient') {
          navigate(`${routes.Product}/${item.ingredient._cursor}`)
        } else {
          navigate(
            buildViewRoute((item as RecipeToRecipe)?.childRecipe.id, false),
          )
        }
      }}
      style={{
        alignItems: 'center',
        backgroundColor: 'white',
        borderBottom: `1px solid ${theme.palette.primary[10].toHexString()}`,
        cursor: 'pointer',
        display: 'grid',
        gap: '0 5px',
        gridTemplateColumns: '1fr 0fr 40px',
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        whiteSpace: 'nowrap',
        width: '100%',
      }}
    >
      <div style={{ overflow: 'hidden' }}>
        {item.__typename === 'RecipeToIngredient' ? (
          <div style={{ textTransform: 'capitalize' }}>
            <Typography
              variant="subtitle1"
              style={{ paddingBottom: 5 }}
              testId="row-name"
            >
              {capitaliseEachWord(item.ingredient.product.name.toLowerCase())}
            </Typography>
            <Typography
              variant="body2"
              style={{ color: theme.palette.primary[60].toHexString() }}
              testId="row-supplier"
            >
              {capitaliseEachWord(
                item.ingredient.product.supplier.name.toLowerCase(),
              )}
            </Typography>
          </div>
        ) : (
          // eslint-disable-next-line
          <div
            style={{
              color: '#0000EE',
              cursor: 'pointer',
              textTransform: 'capitalize',
            }}
          >
            <Typography variant="subtitle1" style={{ paddingBottom: 5 }}>
              {(item as RecipeToRecipe).childRecipe.name.toLowerCase()}
            </Typography>
            <Typography
              variant="button"
              style={{ color: theme.palette.secondary[100].toHexString() }}
            >
              In-house recipe
            </Typography>
          </div>
        )}
      </div>
      <div
        style={{
          alignItems: 'flex-end',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 500, marginBottom: 5 }}
          testId="row-cost"
        >
          £{(item.unitCost ?? 0).toFixed(2)}
        </Typography>
        <Typography
          variant="body2"
          style={{ color: theme.palette.primary[60].toHexString() }}
          testId="row-quantity"
        >
          {toFixedIfNecessary(item.amount, 3)} {item.unit?.name}
        </Typography>
      </div>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Icon iconName="chevronRight" />
      </div>
    </div>
  )
}
