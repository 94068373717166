import { useMutation } from '@apollo/client'
import {
  Button,
  Modal,
  NumberInput,
  Pill,
  ToggleButton,
  Typography,
} from '@getjelly/jelly-ui'
import { useEffect, useMemo, useState } from 'react'

import {
  Mutation,
  MutationUpdateKitchenPosIncludesArgs,
  SalesMixData,
} from '../../../../api'
import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { errorToast } from '../../../../components/toasts'
import { updateKitchenPosIncludes } from '../../FoodFlash/graphql'

type Props = {
  dish: SalesMixData
  saveQuantity: (quantityValue: number | null) => Promise<void>
  open: boolean
  onClose: () => void
}

export function UpdateQuantityAutomatedModel({
  dish,
  saveQuantity,
  open,
  onClose,
}: Props) {
  const [loading, setLoading] = useState(false)
  const [toggling, setToggling] = useState(false)

  const { selectedKitchen, refetchSelectedKitchen } = useKitchen()

  const [refunds, setIncludeRefunds] = useState(
    selectedKitchen?.posIncludeRefunds ?? true,
  )
  const [serviceCharge, setIncludeServiceCharge] = useState(
    selectedKitchen?.posIncludeServiceCharge ?? false,
  )
  const [gratuity, setIncludeGratuity] = useState(
    selectedKitchen?.posIncludeGratuity ?? false,
  )

  useEffect(() => {
    if (selectedKitchen) {
      setIncludeRefunds(selectedKitchen.posIncludeRefunds)
      setIncludeServiceCharge(selectedKitchen.posIncludeServiceCharge)
      setIncludeGratuity(selectedKitchen.posIncludeGratuity)
    } else {
      setIncludeRefunds(true)
      setIncludeServiceCharge(false)
      setIncludeGratuity(false)
    }
  }, [selectedKitchen])

  const [quantity, setQuantity] = useState(
    dish.manual?.qtySold === undefined ? '' : dish.manual.qtySold.toString(),
  )

  const [quantityValue, setQuantityValue] = useState<number | null>(null)

  const automatedQuantity = useMemo(() => {
    const qtySold = dish.automated?.qtySold ?? 0
    return qtySold.toLocaleString()
  }, [dish])

  const [updateKitchen] = useMutation<
    { updateKitchenPosIncludes: Mutation['updateKitchenPosIncludes'] },
    MutationUpdateKitchenPosIncludesArgs
  >(updateKitchenPosIncludes, {
    awaitRefetchQueries: true,
    refetchQueries: ['salesMixInsights'],
  })

  async function saveIncludes(
    includeRefunds: boolean,
    includeServiceCharge: boolean,
    includeGratuity: boolean,
  ) {
    if (toggling) return
    setToggling(true)

    setIncludeRefunds(includeRefunds)
    setIncludeServiceCharge(includeServiceCharge)
    setIncludeGratuity(includeGratuity)

    try {
      await updateKitchen({
        variables: {
          includeGratuity,
          includeRefunds,
          includeServiceCharge,
          kitchenId: selectedKitchen?.id ?? 0,
        },
      })

      await refetchSelectedKitchen()
    } catch {
      errorToast('Unable to update included values.')
    } finally {
      setToggling(false)
    }
  }

  useEffect(() => {
    setQuantity(
      dish.manual?.qtySold === undefined ? '' : dish.manual.qtySold.toString(),
    )
  }, [dish])

  useEffect(() => {
    if (quantity.trim() === '') {
      return setQuantityValue(null)
    }

    const parsed = parseFloat(quantity)

    if (!Number.isNaN(parsed)) {
      setQuantityValue(parsed)
    }
  }, [quantity])

  return (
    <Modal open={open} onClose={onClose}>
      <div className="space-y-6">
        <div className="space-y-6">
          <Typography style="h6" className="text-primary-900">
            Add quantity sold
          </Typography>
        </div>

        <div className="space-y-8">
          <div className="space-y-6">
            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <Typography style="caption" className="text-primary-900">
                  Imported quantity sold
                </Typography>

                <Pill variant="success" label="Automated" />
              </div>

              <div>
                <div className="flex justify-between items-center w-full p-3">
                  <Typography style="body2" className="text-primary-900">
                    Include Refunds
                  </Typography>

                  <div className="shrink-0 flex items-center">
                    <ToggleButton
                      value={refunds}
                      size="small"
                      onChange={() => {
                        void saveIncludes(!refunds, serviceCharge, gratuity)
                      }}
                    />
                  </div>
                </div>

                <NumberInput
                  value={automatedQuantity}
                  disabled={true}
                  onChange={() => void 0}
                  loading={toggling}
                  placeholder="Enter net sales"
                />
              </div>
            </div>

            <div className="space-y-2">
              <Typography style="caption" className="text-primary-900">
                Override
              </Typography>

              <Typography style="body2" className="text-primary-900">
                Override by entering quantity sold below.
              </Typography>

              <NumberInput
                value={quantity}
                onChange={setQuantity}
                placeholder="Enter quantity sold"
              />
            </div>
          </div>

          <Button
            onClick={async () => {
              setLoading(true)
              try {
                await saveQuantity(quantityValue)
              } finally {
                setLoading(false)
              }
            }}
            label="Confirm"
            loading={loading}
            className="w-full"
          />
        </div>
      </div>
    </Modal>
  )
}
