import { useMutation, useQuery } from '@apollo/client'
import {
  ActionModal,
  InfoAlert,
  InsightsDateNavigator,
  InsightsListGroup,
  InsightsNumberCard,
  InsightsScatterChart,
  Modal,
  Pill,
  Table,
  Typography,
} from '@getjelly/jelly-ui'
import { IconChevronRight, IconSelector } from '@tabler/icons-react'
import { format, isBefore, isSameYear } from 'date-fns'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DogIcon } from './components/DogIcon'
import { StarIcon } from './components/StarIcon'
import { UpdateQuantityAutomatedModel } from './components/UpdateQuantityAutomatedModel'
import { UpdateQuantityManualModel } from './components/UpdateQuantityManualModel'
import { salesMixInsights, upsertDishesSoldMutation } from './graphql'

import {
  Mutation,
  MutationCreateOrUpdateDishesSoldArgs,
  Query,
  QuerySalesMixInsightsArgs,
} from '../../../api'
import { useKitchen } from '../../../app/contexts/SelectedKitchen'
import { Loader } from '../../../components'
import { CalendarPeriodSelector } from '../../../components/Insights/CalendarPeriodSelector'
import { MenuSelector } from '../../../components/Insights/MenuSelector'
import { useQueryParamsState } from '../../../hooks/useQueryParamsState'
import { useSyncedDatePeriod } from '../../../hooks/useSyncedDatePeriod'
import { routes } from '../../../routes/Paths'
import { toUTCStartOfDay } from '../../../utils'

function Title({ s, center }: { s: string[]; center?: boolean }) {
  return (
    <div className={`${center ? 'flex justify-center' : ''}`}>
      <div>
        {s.map((str, i) => (
          <Typography key={i} className="text-primary-800" style="subtitle1">
            {str}
          </Typography>
        ))}
      </div>
    </div>
  )
}

function spaceShip(a: number | string, b: number | string) {
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

type SortType =
  | 'profit-asc'
  | 'sold-asc'
  | 'gp-asc'
  | 'alphabetical-asc'
  | 'profit-desc'
  | 'sold-desc'
  | 'gp-desc'
  | 'alphabetical-desc'

type SortAction = {
  subtitle: string
  title: string
  type: SortType
}

const sortActions: SortAction[] = [
  {
    subtitle: 'Most profits first',
    title: 'Highest Total Gross Profit',
    type: 'profit-desc',
  },
  {
    subtitle: 'Most sold first',
    title: 'Highest Qty Sold',
    type: 'sold-desc',
  },
  {
    subtitle: 'Most margin first',
    title: 'Highest GP %',
    type: 'gp-desc',
  },
  {
    subtitle: 'Least profits first',
    title: 'Lowest Total Gross Profit',
    type: 'profit-asc',
  },
  {
    subtitle: 'Least sold first',
    title: 'Lowest Qty Sold',
    type: 'sold-asc',
  },
  {
    subtitle: 'Least margin first',
    title: 'Lowest GP %',
    type: 'gp-asc',
  },
  {
    subtitle: 'A to Z',
    title: 'Name A-Z',
    type: 'alphabetical-desc',
  },
  {
    subtitle: 'Z to A',
    title: 'Name Z-A',
    type: 'alphabetical-asc',
  },
]

export function SalesMix() {
  const { selectedKitchen } = useKitchen()
  const navigate = useNavigate()

  const {
    state: { startDate, endDate, periodEnum, nextStart, previousStart },
    setStartDateParam,
    setPeriodParam,
  } = useSyncedDatePeriod()

  const [automatedModal, setAutomatedModal] = useState(false)
  const [sortModal, setSortModal] = useState(false)
  const [open, setOpen] = useState<number>(0)

  const [sort, setSort] = useState<SortType>('profit-desc')
  const [menuId, setMenuId] = useQueryParamsState('menu', '')

  const { data, loading } = useQuery<
    {
      salesMixInsights: Query['salesMixInsights']
      kitchenToPosProductsCount: Query['kitchenToPosProductsCount']
    },
    QuerySalesMixInsightsArgs
  >(salesMixInsights, {
    variables: {
      kitchenId: selectedKitchen?.id || 0,
      menuId: menuId ? parseInt(menuId) : null,
      period: periodEnum,
      start: toUTCStartOfDay(startDate),
    },
  })

  const [createOrUpdateDishesSold] = useMutation<
    { createOrUpdateDishesSold: Mutation['createOrUpdateDishesSold'] },
    MutationCreateOrUpdateDishesSoldArgs
  >(upsertDishesSoldMutation, {
    awaitRefetchQueries: true,
    refetchQueries: ['salesMixInsights'],
  })

  const dishes = useMemo(() => {
    if (!data?.salesMixInsights) return []
    return data.salesMixInsights.salesMixData
  }, [data])

  const sortedDishes = useMemo(() => {
    return [...dishes].sort((a, b) => {
      const fallback = { gp: 0, profit: 0, qtySold: 0 }
      const aData = a.manual ?? a.automated ?? fallback
      const bData = b.manual ?? b.automated ?? fallback

      if (sort === 'profit-asc') return aData.profit - bData.profit
      if (sort === 'profit-desc') return bData.profit - aData.profit

      if (sort === 'alphabetical-asc') return spaceShip(b.dishName, a.dishName)
      if (sort === 'alphabetical-desc') return spaceShip(a.dishName, b.dishName)

      if (sort === 'gp-asc') return aData.gp - bData.gp
      if (sort === 'gp-desc') return bData.gp - aData.gp

      if (sort === 'sold-asc') return aData.qtySold - bData.qtySold
      if (sort === 'sold-desc') return bData.qtySold - aData.qtySold

      throw new Error('Unknown sort order.')
    })
  }, [dishes, sort])

  const dateText = useMemo(() => {
    if (periodEnum === 'week' && isSameYear(endDate, new Date())) {
      return `${format(startDate, 'd MMM')} - ${format(endDate, 'd MMM')}`
    }

    if (periodEnum === 'week' && !isSameYear(endDate, new Date())) {
      return `${format(startDate, 'd MMM')} - ${format(endDate, 'd MMM yyyy')}`
    }

    if (periodEnum === 'month') {
      return format(startDate, 'MMMM yyy')
    }

    if (periodEnum === 'quarter') {
      return format(startDate, 'qqq yyyy')
    }

    throw new Error('Unknown period.')
  }, [periodEnum, startDate, endDate])

  if (loading && data === undefined) {
    return (
      <>
        <div className="h-12 flex items-center bg-primary-50">
          <MenuSelector
            menuId={menuId ? parseInt(menuId) : null}
            onChange={(id) => setMenuId(id?.toString() || '')}
          />

          <CalendarPeriodSelector
            value={periodEnum}
            onChange={setPeriodParam}
          />
        </div>

        <InsightsDateNavigator
          heading={dateText}
          title=""
          subtitle=""
          forwardDisabled={isBefore(new Date(), nextStart)}
          back={() => setStartDateParam(previousStart.toISOString())}
          forward={() => setStartDateParam(nextStart.toISOString())}
        />

        <Loader />
      </>
    )
  }

  return (
    <>
      <Modal open={automatedModal} onClose={() => setAutomatedModal(false)}>
        <div className="space-y-4">
          <Typography style="h6" className="text-primary-900">
            Automated
          </Typography>

          <Typography style="subtitle2" className="text-primary-900">
            Your POS system automatically shares your sales data with us.
          </Typography>
        </div>
      </Modal>

      <ActionModal
        actions={sortActions.map((sa) => ({
          onClick: () => {
            setSort(sa.type)
            setSortModal(false)
          },
          subtitle: sa.subtitle,
          title: sa.title,
        }))}
        open={sortModal}
        onClose={() => setSortModal(false)}
      />

      <div className="h-12 flex items-center bg-primary-50">
        <MenuSelector
          menuId={menuId ? parseInt(menuId) : null}
          onChange={(id) => setMenuId(id?.toString() || '')}
        />
        <CalendarPeriodSelector value={periodEnum} onChange={setPeriodParam} />
      </div>

      {!!data?.kitchenToPosProductsCount && (
        <InfoAlert
          onClick={() =>
            navigate(routes.Kitchen + routes.Import + routes.Unlinked)
          }
          text={`Your sales mix may be inaccurate because ${data.kitchenToPosProductsCount} POS items need linking. Tap here to link them.`}
        />
      )}

      <InsightsDateNavigator
        heading={dateText}
        title=""
        subtitle=""
        forwardDisabled={isBefore(new Date(), nextStart)}
        back={() => {
          console.log(previousStart.toISOString())
          setStartDateParam(previousStart.toISOString())
        }}
        forward={() => setStartDateParam(nextStart.toISOString())}
      />

      <div className="bg-white sm:flex flex-none">
        <InsightsNumberCard
          title="Total GP"
          data={
            data?.salesMixInsights.totalGp
              ? `${(data.salesMixInsights.totalGp * 100).toFixed(1)} % GP`
              : '-- % GP'
          }
        />

        <InsightsNumberCard
          title="Total Revenue"
          data={(
            (data?.salesMixInsights.totalRevenue ?? 0) / 100
          ).toLocaleString(undefined, {
            currency: 'GBP',
            style: 'currency',
          })}
        />

        <InsightsNumberCard
          title="Total Profit"
          data={(
            (data?.salesMixInsights.totalProfit ?? 0) / 100
          ).toLocaleString(undefined, {
            currency: 'GBP',
            style: 'currency',
          })}
        />
      </div>

      <InsightsListGroup title="Dog Star Chart">
        <InsightsScatterChart
          items={dishes.filter((d) => d.automated || d.manual)}
          profitExtractor={(d) => {
            if (d.manual) {
              return d.manual.profit / 100
            }

            if (d.automated) {
              return d.automated.profit / 100
            }

            return 0
          }}
          quantityExtractor={(d) => {
            if (d.manual) {
              return d.manual.qtySold
            }

            if (d.automated) {
              return d.automated.qtySold
            }

            return 0
          }}
          isWorstExtractor={(d) => d.isDog}
          isBestExtractor={(d) => d.isStar}
          tooltipContent={(d) => (
            <div className="space-y-1 max-w-64">
              <Typography
                style="subtitle1"
                className="text-primary-900 text-left"
              >
                {d.dishName}
              </Typography>

              <div>
                <div className="flex justify-between items-center space-x-2">
                  <div className="flex-shrink-0">
                    <Typography style="subtitle2" className="text-primary-900">
                      QTY Sold:
                    </Typography>
                  </div>

                  <div className="flex-shrink-0 text-right">
                    <Typography style="subtitle1" className="text-primary-900">
                      {d.manual
                        ? d.manual.qtySold
                        : d.automated
                        ? d.automated.qtySold
                        : 0}
                    </Typography>
                  </div>
                </div>

                <div className="flex justify-between items-center space-x-2">
                  <div className="flex-shrink-0">
                    <Typography style="subtitle2" className="text-primary-900">
                      GP:
                    </Typography>
                  </div>

                  <div className="flex-shrink-0 text-right">
                    <Typography style="subtitle1" className="text-primary-900">
                      {(
                        (d.manual
                          ? d.manual.gp
                          : d.automated
                          ? d.automated.gp
                          : 0) * 100
                      ).toFixed(1)}
                      %
                    </Typography>
                  </div>
                </div>

                <div className="flex justify-between items-center space-x-2">
                  <div className="flex-shrink-0">
                    <Typography style="subtitle2" className="text-primary-900">
                      Total Profit:
                    </Typography>
                  </div>

                  <div className="flex-shrink-0 text-right">
                    <Typography style="subtitle1" className="text-primary-900">
                      {(
                        (d.manual
                          ? d.manual.profit
                          : d.automated
                          ? d.automated.profit
                          : 0) / 100
                      ).toLocaleString(undefined, {
                        currency: 'GBP',
                        style: 'currency',
                      })}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          )}
        />
      </InsightsListGroup>

      <div className="flex items-center justify-between px-4 py-2">
        <Typography style="subtitle2" className="text-primary-800">
          Sort table by
        </Typography>

        <div
          className="jui-flex jui-items-center jui-space-x-1 jui-py-2 cursor-pointer"
          onClick={() => setSortModal(true)}
        >
          <Typography style="button" className="jui-text-secondary-400">
            {sortActions.find((sa) => sa.type === sort)?.title ||
              'Unknown Sort'}
          </Typography>

          <IconSelector className="jui-text-secondary-400" />
        </div>
      </div>

      <div className="bg-white">
        <Table
          rows={sortedDishes}
          columns={[
            {
              column: {
                className: 'min-w-24',
                title: <Title s={['Menu Item', 'Name']} />,
              },
              row: {
                contentExtractor: (d) => (
                  <div
                    className="whitespace-normal w-40 sm:w-full space-y-1 cursor-pointer"
                    onClick={() =>
                      navigate(routes.View + `/${d.dishId}` + routes.Dish)
                    }
                  >
                    <Typography style="subtitle2" className="text-primary-800">
                      {d.dishName}

                      {d.isDog && <DogIcon />}
                      {d.isStar && <StarIcon />}
                    </Typography>

                    {d.automated && !d.manual && (
                      <Pill
                        variant="success"
                        label="Automated"
                        onClick={(e) => {
                          e.stopPropagation()
                          setAutomatedModal(true)
                        }}
                      />
                    )}

                    {d.automated && d.manual && (
                      <Pill
                        variant="success"
                        label="Overridden"
                        onClick={(e) => {
                          e.stopPropagation()
                          setAutomatedModal(true)
                        }}
                      />
                    )}
                  </div>
                ),
              },
            },
            {
              column: {
                className: 'min-w-12',
                textAlign: 'left',
                title: <Title s={['Qty', 'Sold']} center={true} />,
              },
              row: {
                contentExtractor: (d) => {
                  async function saveQuantity(qtySold: number | null) {
                    await createOrUpdateDishesSold({
                      variables: {
                        data: {
                          dishId: d.dishId,
                          period: periodEnum,
                          quantity: qtySold,
                          soldAt: toUTCStartOfDay(startDate),
                        },
                        kitchenId: selectedKitchen?.id || 0,
                      },
                    })

                    setOpen(0)
                  }

                  return (
                    <>
                      {d.automated ? (
                        <UpdateQuantityAutomatedModel
                          dish={d}
                          saveQuantity={saveQuantity}
                          open={open === d.dishId}
                          onClose={() => setOpen(0)}
                        />
                      ) : (
                        <UpdateQuantityManualModel
                          dish={d}
                          saveQuantity={saveQuantity}
                          open={open === d.dishId}
                          onClose={() => setOpen(0)}
                        />
                      )}

                      <div
                        className="flex items-center justify-end cursor-pointer"
                        onClick={() => setOpen(d.dishId)}
                      >
                        <Typography
                          className="text-success-400"
                          style="subtitle1"
                        >
                          {d.manual
                            ? d.manual.qtySold.toLocaleString()
                            : d.automated
                            ? d.automated.qtySold.toLocaleString()
                            : 'Add'}
                        </Typography>

                        <IconChevronRight className="text-secondary-400" />
                      </div>
                    </>
                  )
                },
                textAlign: 'right',
              },
            },
            {
              column: {
                className: 'min-w-12',
                textAlign: 'right',
                title: <Title s={['GP', '%']} />,
              },
              row: {
                contentExtractor: (d) =>
                  `${(
                    (d.manual
                      ? d.manual.gp
                      : d.automated
                      ? d.automated.gp
                      : 0) * 100
                  ).toFixed(1)}%`,
                textAlign: 'right',
              },
            },
            {
              column: {
                className: 'min-w-24',
                textAlign: 'right',
                title: <Title s={['Total Gross', 'Profit']} />,
              },
              row: {
                contentExtractor: (d) =>
                  (
                    (d.manual
                      ? d.manual.profit
                      : d.automated
                      ? d.automated.profit
                      : 0) / 100
                  ).toLocaleString(undefined, {
                    currency: 'GBP',
                    style: 'currency',
                  }),
                textAlign: 'right',
              },
            },
          ]}
        />
      </div>
    </>
  )
}
