export function DogIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      className="ml-1 align-top inline-block"
    >
      <g>
        <path
          d="M8 7.16669C7.12 7.16669 6.678 7.44735 6.046 8.44935L5.88333 8.71469L5.62 9.17335C5.5885 9.22971 5.55717 9.28615 5.526 9.34269C5.366 9.63202 5.14533 9.84469 4.76666 10.104L4.4 10.3474C3.77333 10.7654 3.44533 11.0927 3.262 11.6507C3.17933 11.876 3.13133 12.2194 3.13333 12.504C3.13333 13.6287 3.932 14.5 5 14.5L5.16133 14.496C5.24066 14.492 5.31733 14.4847 5.39733 14.4734L5.56266 14.4447L5.65066 14.426L5.84466 14.3774L5.95266 14.3474L6.33266 14.234L6.84133 14.072L7.14466 13.9814C7.498 13.8814 7.77133 13.8334 8 13.8334C8.22933 13.8334 8.502 13.882 8.85533 13.9814L9.15866 14.072L9.668 14.2334L10.0473 14.3474L10.2553 14.4034C10.32 14.4194 10.38 14.4334 10.4373 14.4447L10.6027 14.4734C10.6827 14.4847 10.7593 14.492 10.8387 14.496L11 14.5C12.068 14.5 12.8667 13.6287 12.8667 12.5C12.8667 12.2154 12.818 11.874 12.7287 11.6294C12.5713 11.1467 12.2773 10.814 11.742 10.4094L11.5707 10.2827L11.2187 10.0294C10.7907 9.71602 10.55 9.47869 10.3833 9.17735L10.2033 8.85402L10.0353 8.56602C9.36133 7.43535 8.95666 7.16669 8 7.16669Z"
          fill="#4C596E"
        />
        <path
          d="M13.1867 5.16669H13.1667C12.354 5.18002 11.6 5.87735 11.228 6.83602C10.768 8.01935 10.996 9.31602 11.9447 9.72469C12.1153 9.79735 12.296 9.83335 12.4787 9.83335C13.2993 9.83335 14.0653 9.13135 14.4407 8.16402C14.898 6.98135 14.6673 5.68402 13.7233 5.27602C13.5539 5.20383 13.3709 5.16664 13.1867 5.16669Z"
          fill="#4C596E"
        />
        <path
          d="M6.01667 2.5C5.942 2.5 5.89333 2.50133 5.83667 2.51L5.77467 2.52067C4.75333 2.658 4.17667 3.84667 4.36933 5.09067C4.55067 6.24067 5.344 7.16667 6.316 7.16667L6.44067 7.16333C6.4594 7.16174 6.47808 7.15951 6.49667 7.15667L6.558 7.146C7.58 7.00867 8.156 5.82 7.96333 4.576C7.78333 3.42467 6.99 2.5 6.01667 2.5Z"
          fill="#4C596E"
        />
        <path
          d="M9.98133 2.5C9.00867 2.5 8.21666 3.42534 8.03733 4.57534C7.844 5.82 8.42 7.00867 9.48666 7.15334C9.55533 7.162 9.62 7.16667 9.684 7.16667C10.6107 7.16667 11.3793 6.32334 11.602 5.24467L11.6293 5.09134C11.8227 3.84667 11.2467 2.658 10.18 2.51334C10.1142 2.5043 10.0478 2.49985 9.98133 2.5Z"
          fill="#4C596E"
        />
        <path
          d="M2.81133 5.16669C2.62866 5.16669 2.44866 5.20269 2.28 5.27402C1.32933 5.68402 1.102 6.98202 1.56133 8.16402C1.93666 9.13135 2.70133 9.83335 3.522 9.83335C3.70466 9.83335 3.88466 9.79735 4.05333 9.72602C5.004 9.31602 5.23133 8.01802 4.772 6.83602C4.39666 5.86869 3.632 5.16669 2.81133 5.16669Z"
          fill="#4C596E"
        />
      </g>
      <defs>
        <clipPath id="clip0_1946_12927">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
