import { useQuery } from '@apollo/client'
import { Button, DropdownInput, Modal, Typography } from '@getjelly/jelly-ui'
import { IconSelector } from '@tabler/icons-react'
import { useMemo, useState } from 'react'

import { getMenusQuery } from './graphql'

import { Menu, Query, SortOrder, Status } from '../../api'
import { useKitchen } from '../../app/contexts/SelectedKitchen'

type Props = {
  menuId: number | null
  onChange: (menuId: number | null) => void
}

export function MenuSelector({ menuId, onChange }: Props) {
  const { selectedKitchen } = useKitchen()

  const [showModal, setShowModal] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState<Menu | null>(null)

  const { data, loading } = useQuery<{ menuList: Query['menuList'] }>(
    getMenusQuery,
    {
      variables: {
        orderBy: [{ name: SortOrder.Asc }],
        where: {
          kitchenId: {
            equals: selectedKitchen?.id,
          },
          status: {
            equals: Status.Active,
          },
        },
      },
    },
  )

  const menus = useMemo(() => {
    if (!data?.menuList.nodes) return []
    return data.menuList.nodes
  }, [data])

  const menu = useMemo(
    () => menus.find((m) => m.id === menuId) || null,
    [menuId, menus],
  )

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => {
          setSelectedMenu(menu)
          setShowModal(false)
        }}
      >
        <div className="space-y-8">
          <div className="space-y-6">
            <Typography style="h6" className="text-primary-900">
              Pick a menu for Sales Mix
            </Typography>

            <DropdownInput
              value={selectedMenu}
              options={menus}
              optionToId={(m) => m.id}
              optionToLabel={(m) => m.name}
              onChange={setSelectedMenu}
              placeholder="Select menu"
              loading={loading}
            />
          </div>

          <Button
            label="Confirm"
            onClick={() => {
              onChange(selectedMenu?.id || null)
              setShowModal(false)
            }}
            className="w-full"
          />
        </div>
      </Modal>

      <div
        className={`h-12 px-3 w-full flex items-center justify-center bg-primary-50`}
      >
        <div
          className="flex justify-center items-center cursor-pointer"
          onClick={() => {
            setSelectedMenu(menu)
            setShowModal(true)
          }}
        >
          <Typography
            style="button"
            className="text-secondary-400 line-clamp-2 text-center"
          >
            {menu?.name || 'Select menu'}
          </Typography>

          <div className="flex items-center shrink-0">
            <IconSelector className="text-secondary-400" />
          </div>
        </div>
      </div>
    </>
  )
}
