import { useQuery } from '@apollo/client'
import { useContext, useMemo } from 'react'

import { Query, QueryKitchenToPosProductsArgs } from '../../../../api'
import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { CategoryContext } from '../CategoryContext'
import { KTOCard } from '../components/KTOCard'
import { kitchenToPosProductsQuery } from '../graphql'
import { SearchContext } from '../SearchContext'

export function Unlinked() {
  const { selectedKitchen } = useKitchen()

  const { data } = useQuery<
    { kitchenToPosProducts: Query['kitchenToPosProducts'] },
    QueryKitchenToPosProductsArgs
  >(kitchenToPosProductsQuery, {
    variables: {
      kitchenId: selectedKitchen?.id || 0,
      linked: false,
    },
  })

  const posCategory = useContext(CategoryContext)
  const search = useContext(SearchContext)

  const ktos = useMemo(() => {
    if (!data?.kitchenToPosProducts) {
      return []
    }

    const filtered = data.kitchenToPosProducts.filter((kto) => {
      if (!posCategory || posCategory.externalId === 'all') return true
      return kto.posProduct.posCategoryId === posCategory.id
    })

    if (!search) {
      return filtered
    }

    return filtered.filter((kto) => {
      if (!search) return true

      if (kto.posProduct.name?.toLowerCase().includes(search.toLowerCase())) {
        return true
      }

      return false
    })
  }, [data, posCategory, search])

  return (
    <div className="p-4 space-y-6">
      {ktos.map((kto) => (
        <KTOCard key={kto.id} kto={kto} />
      ))}
    </div>
  )
}
