export function StarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className="ml-1 align-top inline-block"
    >
      <g>
        <path
          d="M5.49533 4.89334L1.242 5.51001L1.16666 5.52534C1.05262 5.55562 0.94866 5.61562 0.865391 5.69921C0.782123 5.78281 0.722532 5.887 0.692705 6.00116C0.662877 6.11532 0.663881 6.23535 0.695615 6.34899C0.727348 6.46263 0.788674 6.56582 0.87333 6.64801L3.95466 9.64734L3.228 13.884L3.21933 13.9573C3.21235 14.0753 3.23684 14.193 3.2903 14.2983C3.34375 14.4037 3.42425 14.493 3.52355 14.557C3.62285 14.621 3.73739 14.6575 3.85543 14.6627C3.97347 14.668 4.09077 14.6417 4.19533 14.5867L7.99933 12.5867L11.7947 14.5867L11.8613 14.6173C11.9714 14.6607 12.091 14.674 12.2078 14.6558C12.3247 14.6377 12.4347 14.5888 12.5264 14.5142C12.6181 14.4395 12.6884 14.3418 12.7299 14.2311C12.7714 14.1204 12.7827 14.0006 12.7627 13.884L12.0353 9.64734L15.118 6.64734L15.17 6.59068C15.2443 6.49919 15.293 6.38965 15.3111 6.27321C15.3293 6.15677 15.3163 6.03759 15.2734 5.92783C15.2305 5.81807 15.1593 5.72163 15.067 5.64835C14.9747 5.57507 14.8646 5.52757 14.748 5.51068L10.4947 4.89334L8.59333 1.04001C8.53831 0.928366 8.45314 0.834353 8.34746 0.768612C8.24177 0.702872 8.11979 0.66803 7.99533 0.66803C7.87087 0.66803 7.74889 0.702872 7.6432 0.768612C7.53752 0.834353 7.45235 0.928366 7.39733 1.04001L5.49533 4.89334Z"
          fill="#4C596E"
        />
      </g>

      <defs>
        <clipPath id="clip0_1946_12935">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
