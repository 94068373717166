import { useQuery } from '@apollo/client'
import {
  Button,
  DropdownInput,
  Modal,
  TextInput,
  Typography,
} from '@getjelly/jelly-ui'
import { IconSearch, IconSelector } from '@tabler/icons-react'
import { useMemo, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { CategoryContext } from './CategoryContext'
import { kitchenToPosProductsCountQuery } from './graphql'
import { SearchContext } from './SearchContext'

import { Query, QueryKitchenToPosProductsArgs } from '../../../api'
import { useKitchen } from '../../../app/contexts/SelectedKitchen'
import {
  NewLayout,
  ScrollMenu,
  ScrollMenuItem,
} from '../../../components/newUi'
import { usePathname } from '../../../hooks'
import { routes } from '../../../routes/Paths'

export type CategoryFragment = {
  id: number
  externalId: string
  name: string
  count: number
}

const allCategory = {
  count: 0,
  externalId: 'all',
  id: 0,
  name: 'All',
}

export function ImportLayout() {
  const navigate = useNavigate()
  const pathname = usePathname()
  const { selectedKitchen } = useKitchen()

  const [posCategory, setPosCategory] = useState<CategoryFragment>(allCategory)
  const [selectedPosCategory, setSelectedPosCategory] =
    useState<CategoryFragment | null>(allCategory)

  const [search, setSearch] = useState('')

  const [filterModal, setFilterModal] = useState(false)

  const { data, loading } = useQuery<
    {
      linkedCount: Query['kitchenToPosProductsCount']
      unlinkedCount: Query['kitchenToPosProductsCount']
      linkedPosCategories: CategoryFragment[]
      unlinkedPosCategories: CategoryFragment[]
    },
    QueryKitchenToPosProductsArgs
  >(kitchenToPosProductsCountQuery, {
    variables: {
      kitchenId: selectedKitchen?.id || 0,
    },
  })

  const kitchenPosCategories = useMemo(() => {
    if (!data) return []

    if (pathname === routes.Kitchen + routes.Import + routes.Linked) {
      return data.linkedPosCategories
    }

    if (pathname === routes.Kitchen + routes.Import + routes.Unlinked) {
      return data.unlinkedPosCategories
    }

    throw new Error('Unknown page.')
  }, [data, pathname])

  const counts = useMemo(() => {
    if (data?.unlinkedCount === undefined || data?.linkedCount === undefined) {
      return { linked: 0, unlinked: 0 }
    }

    return { linked: data.linkedCount, unlinked: data.unlinkedCount }
  }, [data])

  const posCategories: CategoryFragment[] = useMemo(() => {
    if (kitchenPosCategories === undefined) {
      return [allCategory]
    }

    allCategory.count = kitchenPosCategories.reduce(
      (acc, koc) => acc + koc.count,
      0,
    )

    const sortedCategories = [allCategory, ...kitchenPosCategories].sort(
      (a, b) => {
        if (a.externalId === 'all') return -1
        if (a.externalId === 'other') return 1
        return a.name > b.name ? 1 : -1
      },
    )

    const current = sortedCategories.find((c) => c.id === posCategory?.id)

    if (!posCategory || !current) {
      setPosCategory(sortedCategories[0] || null)
      setSelectedPosCategory(sortedCategories[0] || null)
    } else {
      setPosCategory(current)
      setSelectedPosCategory(current)
    }

    return sortedCategories
  }, [posCategory, kitchenPosCategories])

  return (
    <>
      <NewLayout
        title="Import from POS"
        subtitle="Kitchen"
        onBack={() => navigate(-1)}
      />

      <div className="space-y-1">
        <ScrollMenu>
          <ScrollMenuItem
            text={`Unlinked (${counts.unlinked})`}
            selected={
              pathname === routes.Kitchen + routes.Import + routes.Unlinked
            }
            fullWidth={true}
            route={routes.Kitchen + routes.Import + routes.Unlinked}
          />

          <ScrollMenuItem
            text={`Linked (${counts.linked})`}
            selected={
              pathname === routes.Kitchen + routes.Import + routes.Linked
            }
            fullWidth={true}
            route={routes.Kitchen + routes.Import + routes.Linked}
          />
        </ScrollMenu>
      </div>

      {posCategories[0].count === 0 ? (
        <>
          {pathname === routes.Kitchen + routes.Import + routes.Linked ? (
            <div className="py-6 px-8 text-center space-y-2">
              <Typography style="subtitle1" className="text-primary-900">
                You have not linked any items yet
              </Typography>

              <Typography style="subtitle2" className="text-primary-600">
                Here is where you will be able to see POS items that have been
                linked to a Jelly dish!
              </Typography>
            </div>
          ) : (
            <div className="py-6 px-8 text-center space-y-2">
              <Typography style="subtitle1" className="text-primary-900">
                No more items to link
              </Typography>

              <Typography style="subtitle2" className="text-primary-600">
                It seems you have gone through all the pending items that
                required your approval, you are a star!
              </Typography>
            </div>
          )}
        </>
      ) : (
        <>
          <Modal
            open={filterModal}
            onClose={() => {
              setSelectedPosCategory(posCategory)
              setFilterModal(false)
            }}
          >
            <div className="space-y-4">
              <div className="space-y-6">
                <Typography style="h6" className="text-primary-900">
                  Filter
                </Typography>

                <div className="space-y-2">
                  <Typography style="caption" className="text-primary-900">
                    By POS category
                  </Typography>

                  <DropdownInput
                    placeholder="Filter by category"
                    value={posCategory}
                    options={posCategories}
                    optionToId={(o) => o.id}
                    optionToLabel={(o) => `${o.name} (${o.count})`}
                    onChange={(c) => setSelectedPosCategory(c)}
                    loading={loading}
                  />
                </div>
              </div>

              <Button
                className="w-full"
                label="Submit"
                disabled={!selectedPosCategory}
                onClick={() => {
                  if (!selectedPosCategory) return
                  setPosCategory(selectedPosCategory)
                  setFilterModal(false)
                }}
              />
            </div>
          </Modal>

          <div
            className="px-4 py-3 flex items-center justify-center cursor-pointer"
            onClick={() => setFilterModal(true)}
          >
            <Typography
              style="button"
              className="text-secondary-400 line-clamp-1"
            >
              {posCategory.externalId === 'all'
                ? 'Filter by POS categories'
                : posCategory.name}
            </Typography>

            <IconSelector className="text-secondary-400 shrink-0" />
          </div>

          <div className="px-4">
            <TextInput
              placeholder="Search by name"
              value={search}
              onChange={setSearch}
              icon={IconSearch}
            />
          </div>
        </>
      )}

      <CategoryContext.Provider value={posCategory}>
        <SearchContext.Provider value={search}>
          <Outlet />
        </SearchContext.Provider>
      </CategoryContext.Provider>
    </>
  )
}
