import {
  Anchor,
  Button,
  Modal,
  NumberInput,
  Typography,
} from '@getjelly/jelly-ui'
import { useEffect, useState } from 'react'

import { SalesMixData } from '../../../../api'

type Props = {
  dish: SalesMixData
  saveQuantity: (quantityValue: number | null) => Promise<void>
  open: boolean
  onClose: () => void
}

export function UpdateQuantityManualModel({
  dish,
  saveQuantity,
  open,
  onClose,
}: Props) {
  const [loading, setLoading] = useState(false)

  const [quantity, setQuantity] = useState(
    dish.manual?.qtySold === undefined ? '' : dish.manual.qtySold.toString(),
  )

  const [quantityValue, setQuantityValue] = useState<number | null>(null)

  useEffect(() => {
    setQuantity(
      dish.manual?.qtySold === undefined ? '' : dish.manual.qtySold.toString(),
    )
  }, [dish])

  useEffect(() => {
    if (quantity.trim() === '') {
      return setQuantityValue(null)
    }

    const parsed = parseFloat(quantity)

    if (!Number.isNaN(parsed)) {
      setQuantityValue(parsed)
    }
  }, [quantity])

  return (
    <Modal open={open} onClose={onClose}>
      <div className="space-y-6">
        <div className="space-y-6">
          <Typography style="h6" className="text-primary-900">
            Add quantity sold
          </Typography>
        </div>

        <div className="space-y-8">
          <div className="space-y-6">
            <Typography
              style="subtitle1"
              className="text-primary-900 text-center"
            >
              Automated
            </Typography>

            <div className="space-y-6">
              <Typography style="subtitle2" className="text-primary-900">
                Get all your sales added automatically!
              </Typography>

              <div className="space-y-1">
                <div className="flex space-x-1">
                  <Typography
                    style="subtitle2"
                    className="text-primary-900 !font-bold"
                  >
                    Step 1:
                  </Typography>

                  <Typography style="subtitle2" className="text-primary-900">
                    go to
                  </Typography>

                  <Anchor
                    style="subtitle2"
                    className="text-primary-900 underline"
                  >
                    Settings -&gt; Integrations
                  </Anchor>
                </div>

                <div className="flex space-x-1">
                  <Typography
                    style="subtitle2"
                    className="text-primary-900 !font-bold"
                  >
                    Step 2:
                  </Typography>

                  <Typography style="subtitle2" className="text-primary-900">
                    connect your POS system
                  </Typography>
                </div>
              </div>
            </div>

            <div className="flex items-center space-x-2.5">
              <div className="flex-1 bg-primary-100 h-[1px]"></div>

              <Typography style="subtitle1" className="text-primary-900">
                or
              </Typography>

              <div className="flex-1 bg-primary-100 h-[1px]"></div>
            </div>

            <Typography
              style="subtitle1"
              className="text-primary-900 text-center"
            >
              Manual
            </Typography>

            <Typography style="subtitle2" className="text-primary-900">
              Enter quantity sold for the calendar period here.
            </Typography>

            <div className="space-y-2">
              <Typography style="caption" className="text-primary-900">
                Quantity sold
              </Typography>

              <NumberInput
                value={quantity}
                onChange={setQuantity}
                placeholder="Enter quantity sold"
              />
            </div>
          </div>

          <Button
            onClick={async () => {
              setLoading(true)
              try {
                await saveQuantity(quantityValue)
              } finally {
                setLoading(false)
              }
            }}
            label="Save"
            loading={loading}
            className="w-full"
          />
        </div>
      </div>
    </Modal>
  )
}
