import { gql } from '@apollo/client'

export const kitchenToPosProductsQuery = gql`
  query kitchenToPosProductsQuery($kitchenId: Int!, $linked: Boolean) {
    kitchenToPosProducts(kitchenId: $kitchenId, linked: $linked) {
      id
      kitchenId
      posProduct {
        id
        name
        price
        posCategoryId
      }
      dish {
        id
        name
        price
        ingredientCount
      }
    }
  }
`

export const dishTypesQuery = gql`
  query dishTypesQuery {
    dishTypes {
      name
    }
  }
`

export const createOneDishMutation = gql`
  mutation createOneDishMutation(
    $data: CreateOneDishInputObject!
    $kitchenId: Int!
  ) {
    createOneDish(data: $data, kitchenId: $kitchenId) {
      id
    }
  }
`

export const kitchenToPosProductsCountQuery = gql`
  query kitchenToPosProductsCountQuery($kitchenId: Int!) {
    linkedCount: kitchenToPosProductsCount(kitchenId: $kitchenId, linked: true)
    unlinkedCount: kitchenToPosProductsCount(
      kitchenId: $kitchenId
      linked: false
    )
    linkedPosCategories: kitchenPosCategories(
      kitchenId: $kitchenId
      linked: true
    ) {
      id
      externalId
      name
      count: posProductsCount(kitchenId: $kitchenId, linked: true)
    }
    unlinkedPosCategories: kitchenPosCategories(
      kitchenId: $kitchenId
      linked: false
    ) {
      id
      externalId
      name
      count: posProductsCount(kitchenId: $kitchenId, linked: false)
    }
  }
`

export const dishesQuery = gql`
  query dishes(
    $cursor: DishWhereUniqueInput
    $orderBy: [DishOrderByInput!]
    $skip: Int
    $take: Int
    $where: DishWhereInput
  ) {
    dishes(
      cursor: $cursor
      orderBy: $orderBy
      skip: $skip
      take: $take
      where: $where
    ) {
      _cursor
      id
      name
      price
      ingredientCount
      isMatched
    }
  }
`

export const linkPosProductMutation = gql`
  mutation linkPosProduct($data: LinkPosProductInput!) {
    linkPosProduct(data: $data)
  }
`

export const unlinkPosProductMutation = gql`
  mutation unlinkPosProduct($data: LinkPosProductInput!) {
    unlinkPosProduct(data: $data)
  }
`
